<template>
    <b-overlay :show="showLoader">
        <b-card :title="getTitle">
            <b-alert class="p-1 text-center" show variant="warning">Po dodajanju je potrebnih nekaj sekund, da se le ti prikažejo. Če dodani pogoji niso vidni, poskusite osvežiti spletno stran.</b-alert>
            <BusinessPrivacyPolicyComponent :model="object" />
            <div class="d-flex justify-content-end mt-2">
                <b-button @click="submit">Dodaj</b-button>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
    import BusinessPrivacyPolicyComponent from './BusinessPrivacyPolicyComponent.vue'
    import {BButton, BCard, BAlert, BOverlay} from 'bootstrap-vue'
    export default {
        components:{
            BusinessPrivacyPolicyComponent,
            BCard,
            BButton,
            BAlert,
            BOverlay
        },
        data() {
            return {
                showLoader: false,
                object:
                    {
                        title: '',
                        content: '',
                        active: true,
                        manager: this.$route.params.manager
                    }
            }
        },
        methods: {
            async submit() {
                try {
                    this.showLoader = true
                    await this.$http.post('/api/management/v1/business_privacy_policies', this.object)
                    this.$printSuccess('Pogoji so bili dodani')
                    setTimeout(() => {
                        this.$router.push({name: 'business-privacy-policies'})
                    }, 1000)
                } catch (err) {
                    this.$printError('Prislo je do napake pri dodajanju')
                } finally {
                    this.showLoader = false
                }
            }
        },
        computed:{
            getTitle() {
                if (this.$route.params.manager === 'stanInvest') return 'Staninvest d.o.o.'
                else if (this.$route.params.manager === 'habit') return 'Habit d.o.o.'
                //else if (this.$route.params.manager === 'basic') return 'Splošni pogoji'
                else return ''
            }
        }
    }
</script>